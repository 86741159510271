<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="md:ml-12 pr-4 md:pr-0" v-if="categories">
      <div v-if="categories.length === 0" class="h-128">
        <div
          class="flex flex-col text-center items-center justify-center h-full"
        >
          <span class="font-semibold text-xl text-gray-500"
            >No categories are available.<br />Create a new category by clicking
          </span>
          <div class="mx-auto mt-8">
            <trac-button
              v-if="
                permissionsModules[4].parent.permissionsForUser.createCategory
              "
              class="uppercase text-xs"
              @button-clicked="gotoAddCategory"
              >New Category
            </trac-button>
            <p v-else class="text-sm">No permissions to view this.</p>
          </div>
        </div>
      </div>
      <div class="" v-else>
        <div class="flex flex-row items-end justify-between mb-8">
          <div class="flex-column mt-12">
            <!-- <h2>Products</h2> -->
            <h3 class="font-bold mt-4 text-lg">Categories</h3>
          </div>
          <div
            v-if="
              categories &&
              permissionsModules[4].parent.permissionsForUser.createCategory
            "
            class="flex-column"
          >
            <trac-button
              class="uppercase text-xs"
              @button-clicked="gotoAddCategory"
              >New Category</trac-button
            >
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
          <div
            v-for="(category, i) in sortedCategories"
            :key="i"
            @click="gotoCategoryDetail(category)"
            class="
              small-shadow
              bg-white
              rounded-lg
              card-gradient
              hover:text-white
              cursor-pointer
            "
          >
            <div class="flex flex-row items-center">
              <div
                class="
                  rounded-full
                  flex flex-column
                  bg-accentLight
                  mx-5
                  my-8
                  py-2
                  items-center
                "
              >
                <img class="mx-auto" src="../../assets/svg/Vector.svg" />
              </div>
              <ul class="flex-column mr-5 hover:text-white">
                <li class="flex-column font-semibold text-sm capitalize">
                  {{ category.name }}
                </li>
                <li class="text-xs">{{ `${category.count} Product(s)` }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { permissionsModules } from "./../settings/permission";

import { eventBus } from "./../../main";
import categories from '../../store/modules/categories';

export default {
  name: "ProductCategories",
  data() {
    return {
      isLoading: false,
      categories: null,
      permissionsModules,
    };
  },
  async created() {
    this.isLoading = true;
    DELETE_LOCAL_DB_DATA("category-detail");
    await this.fetchAllCategories();
    this.isLoading = false;
  },
  computed: {
    sortedCategories() {
      return this.categories.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
  methods: {
    gotoCategoryDetail(category) {
      SAVE_LOCAL_DB_DATA("category-detail", category);
      this.$router.push({ name: "CategoriesList" });
    },
    gotoAddCategory() {
      this.$router.push({ name: "AddCategories" });
    },
    async fetchAllCategories() {
      await this.$store.dispatch("FETCH_ALL_CATEGORIES");
      const res = this.$store.getters["GET_ALL_CATEGORIES"];

      if (res.status) {
        const data = res.data || [{ name: "", count: 0 }];
        if (data.length > 0) {
          this.categories = data.slice(1, data.length);
        }
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
  },
};
</script>

<style scoped>
.card-gradient:hover {
  background: linear-gradient(180deg, #0143aa 0%, #003283 100%);
}
</style>
